import { useRef, useEffect } from 'react';
import videojs  from 'video.js';

export default function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export function useVideojs({
  src,
  autoplay,
  onReady,
  onPlay,
  onPause,
  onTimeUpdate,
  onSeeking,
  onSeeked,
  onEnd,
  className,
  poster,
  ...props
}) {
  const vjsId = `vjs-${Date.now()}`;
  const vjsRef = useRef(null);

  const player = useRef(null)

  const previousSrc = usePrevious(src);
  const previousPoster = usePrevious(poster);
  const previousAutoplay = usePrevious(autoplay);

  useEffect(() => {

    const _initPlayer = () => {
      player.current = videojs(vjsRef.current, props);
      player.current.src(src);
      player.current.autoplay(autoplay);
    };

    const _changeSrc = () => {
      player.current = videojs(vjsRef.current);
      player.current.src(src);
    };
    const _changePoster = () => {
      if(player.current){
        player.current.poster(poster)
      }

    };

    const _changeAutoplay = () => {
      player.current = videojs(vjsRef.current);
      player.current.autoplay(autoplay);
    };

    const _initPlayerEvents = () => {
      let currentTime = 0;
      let previousTime = 0;
      let position = 0;

      player.current.ready(() => {
        if (onReady) {
          onReady(player.current)
        }
      });

      player.current.on('play', () => {
        if (onPlay) {
          onPlay(player.current.currentTime())
        }
      });

      player.current.on('pause', () => {
        if (onPause) {
          onPause(player.current.currentTime());
        }
      });

      player.current.on('timeupdate', () => {
        if (onTimeUpdate) {
          onTimeUpdate(player.current.currentTime());
          previousTime = currentTime;
          currentTime = player.current.currentTime();
          if (previousTime < currentTime) {
            position = previousTime;
            previousTime = currentTime;
          }
        }
      });

      player.current.on('seeking', () => {
        if (onSeeking) {
          player.current.off('timeupdate');
          player.current.one('seeked');
          onSeeking(player.current.currentTime());
        }
      });

      player.current.on('seeked', () => {
        if (onSeeked) {
          const completeTime = Math.floor(player.current.currentTime());
          onSeeked(position, completeTime);
        }
      });

      player.current.on('ended', () => {
        if (onEnd) {
          onEnd(player.current.currentTime());
        }
      });
    };

    const _unregisterEvents = () => {
      player.current.off('play');
      player.current.off('pause');
      player.current.off('timeupdate');
      player.current.off('seeking');
      player.current.off('seeked');
      player.current.off('ended');
    };

    if ((previousSrc !== src) && !previousSrc) {
      _initPlayer();
      _initPlayerEvents();
    }
    if((previousSrc !== src) && previousSrc) {
      _changeSrc();
      _unregisterEvents();
      _initPlayerEvents();
    }
    if(previousPoster !== poster || !previousPoster){
      _changePoster()
    }

    if((previousAutoplay !== autoplay) && (previousAutoplay !== undefined)) {
      _changeAutoplay();
      _unregisterEvents();
      _initPlayerEvents();
    }

  }, [    autoplay,
    onReady,
    onPlay,
    onPause,
    onTimeUpdate,
    onSeeking,
    onSeeked,
    onEnd,
    className,
    poster,
    props, previousAutoplay, previousPoster, previousSrc, src]);

  const videoClassNames = `video-js ${className} ${props.bigPlayButtonCentered ? 'vjs-big-play-centered' : ''}`;

  return { vjsRef, vjsId, vjsClassName: videoClassNames };
}


