import React from 'react'

import './video-js.css'
import { useVideojs } from '../../../hooks/videojs/useVideoJs'

const Vid = ({ videoJsOptions }) => {
  const onPlay = (currentTime) => {
  }

  const onPause = (currentTime) => {
  }

  const onEnd = (currentTime) => {
  }

  const onTimeUpdate = (currentTime) => {
  }
  const { vjsId, vjsRef, vjsClassName } = useVideojs({
    ...videoJsOptions.video,
    controls: true,
    responsive: true,
    bigPlayButtonCentered: true,
    liveUi: true,
    onPlay,
    onPause,
    onEnd,
    onTimeUpdate,
  })

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  return (
    <div data-vjs-player>
      <video
        vjs-fluid="true"
        ref={vjsRef} id={vjsId} className={`${vjsClassName} flex-col flex-grow w-full vjs-16-9 mt-5 rounded`}></video>
    </div>
  )
}
export default Vid
